import React, { useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';
import Zone from './components/Zone';
import IconLeft from './components/IconLeft';
import IconRight from './components/IconRight';
import { getFunctionSystemValue } from '../../../../libs/functionHelper';
import { useBravoProxy } from '../../../../../BravoProxy/BravoProxy';

const useStyles = createUseStyles({
    container: {},
    visual: {
        display: 'flex',
        flexDirection: 'column',
        width: 160,
        height: 171,
    },
    top: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 5,
        justifyContent: 'center',
    },
    seperator: {
        height: 1,
        backgroundColor: 'white',
        opacity: 0.4,
    },
    bottom: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        marginTop: 5,
        justifyContent: 'center',
    },
});

const ABBIDCC = (props) => {
    const { systemId } = props;
    const classes = useStyles();
    const { state } = useBravoProxy();
    const zone1Enabled = getFunctionSystemValue(state, systemId, '309');
    const zone2Enabled = getFunctionSystemValue(state, systemId, '409');

    return useMemo(() => {
        return (
            <div className={classes.container}>
                <div className={clsx(classes.visual)}>
                    {zone1Enabled === '1' && (
                        <div className={clsx(classes.top)}>
                            <Zone
                                {...props}
                                zoneConfig={{
                                    active: '308',
                                    temperature: {
                                        actual: '300',
                                    },
                                    ventilation: {
                                        actual: '900',
                                    },
                                    RH: {
                                        enabledExpression: '{700} == 1',
                                        actual: '701',
                                    },
                                    THI: {
                                        enabledExpression: '{700} == 1 && {703} == 1',
                                        actual: '301',
                                    },
                                    extraControl: {
                                        mode: '500', // soaking or spraying
                                        activeExpression: '{508} == 1',
                                    },
                                }}
                            />
                        </div>
                    )}
                    {zone1Enabled === '1' && zone2Enabled === '1' && <div className={clsx(classes.seperator)}></div>}
                    {zone2Enabled === '1' && (
                        <div className={clsx(classes.bottom)}>
                            <Zone
                                {...props}
                                zoneConfig={{
                                    active: '408',
                                    temperature: {
                                        actual: '400',
                                    },
                                    ventilation: {
                                        actual: '1000',
                                    },
                                    RH: {
                                        enabledExpression: '{700} == 1',
                                        actual: '701',
                                    },
                                    THI: {
                                        enabledExpression: '{700} == 1 && {703} == 1',
                                        actual: '401',
                                    },
                                    extraControl: {
                                        mode: '600', // soaking or spraying
                                        activeExpression: '{608} == 1',
                                    },
                                }}
                            />
                        </div>
                    )}
                </div>
                <IconLeft {...props} />
                <IconRight {...props} />
            </div>
        );
    }, [zone1Enabled, zone2Enabled]);
};

ABBIDCC.propTypes = {};

export default ABBIDCC;
