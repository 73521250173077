import React, { useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';
import { evaluateExpression, getFunctionUserValue } from '../../../../../../libs/functionHelper';
import { useBravoProxy } from '../../../../../../../BravoProxy/BravoProxy';

const useStyles = createUseStyles({
    container: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        '& .value': {
            position: 'relative',
            top: 5,
            fontSize: 23,
        },
    },
});

const THI = (props) => {
    const { zoneConfig, systemId } = props;
    const classes = useStyles();
    const { state } = useBravoProxy();
    const enabled = evaluateExpression(state, systemId, zoneConfig.THI.enabledExpression);
    const THI = getFunctionUserValue(state, systemId, zoneConfig.THI.actual);
    return useMemo(() => {
        if (enabled) {
            return (
                <div className={clsx(classes.container)}>
                    <div className='value'>THI {THI}</div>
                </div>
            );
        }
        return null;
    }, [enabled, THI]);
};

THI.propTypes = {};

export default THI;
