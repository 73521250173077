import { forOwn } from 'lodash';
import { loadDeviceConfigurations } from './deviceConfiguration';
import { BravoProxyConfig } from '../../../config';
import { uniq } from './util';

export const loadDevicesData = async (accessToken, devices) => {
    // load device configurations
    const deviceConfigurations = await loadDeviceConfigurations(devices);

    const uniqueSystemIds = uniq(devices.map((o) => o.systemId));

    // used the setup the device state
    let deviceData = {};

    // fist load initial device state
    devices.forEach((device) => {
        const { product, version, systemId, online } = device;
        deviceData[systemId] = {
            product,
            version,
            config: null,
            state: {},
            alarms: {},
            online,
            subscribed: false,
        };

        const deviceConfigurationKey = `${product}_${version}`;
        const deviceConfiguration = deviceConfigurations[deviceConfigurationKey];
        if (deviceConfiguration) {
            deviceData[systemId].config = deviceConfiguration;
            forOwn(deviceConfiguration.functions, (functionInfo, functionId) => {
                deviceData[systemId].state[functionId] = functionInfo.defaultValue;
            });
        }
    });

    // then load white listed data
    const response = await fetch(`${BravoProxyConfig.apiBackend}/api/device_cache`, {
        method: 'POST',
        headers: new Headers({
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        }),
        body: JSON.stringify({
            systemIds: uniqueSystemIds,
            cacheLevel: 1, // whitelist
        }),
    });
    const deviceCache = await response.json();
    if (deviceCache) {
        forOwn(deviceCache, (cache, systemId) => {
            const { functions: functionValues, alarms } = cache;
            // set function values
            functionValues.forEach((functionValue) => {
                const { fid, fv } = functionValue;
                deviceData[systemId].state[fid] = fv;
            });
            // set alarms
            deviceData[systemId].alarms = alarms;
        });
    }

    return deviceData;
};
