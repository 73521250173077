import React, { useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';
import { getFunctionSystemValue, getFunctionUserValue } from '../../../../../../libs/functionHelper';
import { useBravoProxy } from '../../../../../../../BravoProxy/BravoProxy';

const icons = {
    // RH
    RH: (
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 60 60'>
            <g id='OLD'>
                <path d='M27.52,23.35c-.5-2.12-1.83-3.75-3.35-5.58A29,29,0,0,1,21.37,14a30,30,0,0,1-2.81,3.77C16.65,20.06,15,22.05,15,25.1c0,5.17,4.87,5.57,6.37,5.57.22,0,.52,0,.85,0a29.58,29.58,0,0,1,4.17-5.91Zm-7.71,4.42c-.34.5-2.06,1.28-3-1.07-1.18-2.91,1.71-5.65,1.71-5.65a6.66,6.66,0,0,0,.07,3.7C18.85,25.89,20.63,26.56,19.81,27.77Z' />
                <path d='M30.67,22.4a36.76,36.76,0,0,1-3.47,4.67c-2.36,2.83-4.4,5.27-4.4,9,0,6.38,6,6.88,7.86,6.88s7.87-.5,7.87-6.88c0-3.78-2-6.22-4.4-9A35.11,35.11,0,0,1,30.67,22.4Zm-1.93,17C28.32,40,26.21,41,25,38.09c-1.46-3.58,2.11-7,2.11-7a8.24,8.24,0,0,0,.08,4.57C27.56,37.1,29.75,37.93,28.74,39.42Z' />
                <path d='M39.65,15.86A24.35,24.35,0,0,1,37.3,19c-1.38,1.66-2.6,3.12-2.91,5.14l.45.54.75.91c-.2-2.07,1.66-3.84,1.66-3.84a5.71,5.71,0,0,0,.06,3.11c.23.95,1.73,1.52,1,2.53a1.32,1.32,0,0,1-1,.45,19.08,19.08,0,0,1,1.25,1.92,9.73,9.73,0,0,0,1.06.07c1.26,0,5.35-.33,5.35-4.67C45,22.62,43.61,21,42,19A25.22,25.22,0,0,1,39.65,15.86Z' />
            </g>
        </svg>
    ),
};

const useStyles = createUseStyles({
    container: {
        display: 'flex',
        position: 'absolute',
        left: 0,
        bottom: 0,
        width: 180,
        height: 40,
        opacity: 0.4,
        alignItems: 'center',
        '& .icon': {
            width: 40,
            height: 40,
            fill: '#FFFFFF',
        },
        '& .value': {
            paddingLeft: 10,
            fontSize: 18,
        },
    },
});

const IconLeft = (props) => {
    const { systemId } = props;
    const { state } = useBravoProxy();
    const RHEnabled = getFunctionSystemValue(state, systemId, '700');

    return useMemo(() => {
        if (RHEnabled === '1') {
            return <RHControl {...props} />;
        }
        return null;
    }, [RHEnabled]);
};

const RHControl = (props) => {
    const { systemId } = props;
    const classes = useStyles();
    const { state } = useBravoProxy();
    const value = getFunctionUserValue(state, systemId, '701');
    return useMemo(() => {
        return (
            <div className={clsx(classes.container)}>
                <div className='icon'>{icons.RH}</div>
                <div className='value'>{value} %</div>
            </div>
        );
    }, [value]);
};

IconLeft.propTypes = {};

export default IconLeft;
