import React from 'react';
import PropTypes from 'prop-types';
import IconLeft from './components/IconLeft';
import IconRight from './components/IconRight';
import { createUseStyles } from 'react-jss';
import SecondInputConfiguration from './components/SecondInputConfiguration';
import ActualTemperature from './components/ActualTemperature';
import clsx from 'clsx';
import ActualVentilation from './components/ActualVentilation';
import MinMaxVentilation from './components/MinMaxVentilation';

const useStyles = createUseStyles({
    container: {},
    visual: {
        display: 'flex',
        flexDirection: 'column',
        width: 132,
        height: 176,
    },
    top: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    seperator: {
        height: 1,
        backgroundColor: 'white',
        opacity: 0.4,
    },
    bottom: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
});

const BBVK = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <div className={clsx(classes.visual)}>
                <div className={clsx(classes.top)}>
                    <SecondInputConfiguration {...props} />
                    <ActualTemperature {...props} />
                </div>
                <div className={clsx(classes.seperator)}></div>
                <div className={clsx(classes.bottom)}>
                    <ActualVentilation {...props} />
                    <MinMaxVentilation {...props} />
                </div>
            </div>
            <IconLeft {...props} />
            <IconRight {...props} />
        </div>
    );
};

BBVK.propTypes = {};

export default BBVK;
