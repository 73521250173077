import React, { useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';
import { getFunctionSystemValue, getFunctionUserValue } from '../../../../../../libs/functionHelper';
import { useBravoProxy } from '../../../../../../../BravoProxy/BravoProxy';

const icons = {
    // default
    default: (
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 29.42 29.01'>
            <path d='M22.42,18.18V9h-2v9.18a3,3,0,1,0,2,0Z' />
            <path d='M26.42,0h-10V14.76A8,8,0,1,0,27.67,16a8.62,8.62,0,0,0-1.25-1.24V12h3V10h-3V8h3V6h-3V4h3V2h-3Zm1,21A6,6,0,1,1,18,16.08l.43-.3V2h6V15.78l.43.3A6,6,0,0,1,27.42,21Z' />
            <polygon points='5.71 16.74 1.42 12.44 0 13.86 5.71 19.56 13.42 11.86 12 10.44 5.71 16.74' />
        </svg>
    ),
    // outside temperature
    outside_temperature: (
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 13'>
            <g id='Layer_2' data-name='Layer 2'>
                <g id='Layer_1-2' data-name='Layer 1'>
                    <rect x='5.75' width='1.5' height='2' />
                    <rect x='1.75' y='1.19' width='1' height='2.12' transform='translate(-0.94 2.25) rotate(-45)' />
                    <rect x='1.19' y='10.25' width='2.12' height='1' transform='translate(-6.94 4.74) rotate(-45)' />
                    <rect y='5.75' width='2' height='1.5' />
                    <rect x='5.75' y='11' width='1.5' height='2' />
                    <path d='M12,7.09V2H11V7.09a1.5,1.5,0,1,0,1,0Z' />
                    <path d='M16,3V2H14V0H9V4.06a3.5,3.5,0,1,0-.82,5.5,3.49,3.49,0,1,0,6.66-2.09A3.46,3.46,0,0,0,14,6.07V5h2V4H14V3ZM4,6.5a2.51,2.51,0,0,1,5-.39A3.56,3.56,0,0,0,8,8.48a2.48,2.48,0,0,1-4-2ZM11.5,11A2.5,2.5,0,0,1,9,8.5a2.47,2.47,0,0,1,.84-1.85L10,6.5V1h3V6.5l.17.15A2.46,2.46,0,0,1,14,8.5,2.5,2.5,0,0,1,11.5,11Z' />
                </g>
            </g>
        </svg>
    ),
    // second temperature sensor (heating)
    heating_temperature: (
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 29.01'>
            <g id='Layer_2' data-name='Layer 2'>
                <g id='Layer_1-2' data-name='Layer 1'>
                    <g id='OLD'>
                        <path d='M9,18.18V9H7v9.18a3,3,0,1,0,2,0Z' />
                        <path d='M13,0H3V14.76A8,8,0,1,0,14.25,16,8.62,8.62,0,0,0,13,14.76V12h3V10H13V8h3V6H13V4h3V2H13Zm1,21a6,6,0,1,1-9.43-4.92l.43-.3V2h6V15.78l.43.3A6,6,0,0,1,14,21Z' />
                        <rect x='21' y='19' width='2' height='8' />
                        <rect x='25' y='19' width='2' height='8' />
                    </g>
                </g>
            </g>
        </svg>
    ),
};

const useStyles = createUseStyles({
    container: {
        display: 'flex',
        opacity: 0.4,
        height: 35,
        '& .icon': {
            fill: 'white',
            width: 25,
            height: 25,
        },
        '& .value': {
            position: 'relative',
            top: -4,
            left: 5,
            fontSize: 30,
        },
        '& .value_rest': {
            position: 'relative',
            top: 0,
            left: 5,
            fontSize: 12,
            lineHeight: 1,
        },
    },
});

const SecondInputConfiguration = (props) => {
    const { systemId } = props;
    const classes = useStyles();
    const { state } = useBravoProxy();
    const configType = getFunctionSystemValue(state, systemId, '100');

    return useMemo(() => {
        switch (configType) {
            case '0':
                return <DefaultConfig {...props} />;
            case '1':
                return <OutsideTemperatureConfig {...props} />;
            case '2':
                return <HeatingTemperatureConfig {...props} />;
            default:
                return <div className={classes.container}></div>;
        }
    }, [configType]);
};

const DefaultConfig = (props) => {
    const { systemId } = props;
    const classes = useStyles();
    const { state } = useBravoProxy();
    const value = getFunctionUserValue(state, systemId, '212');
    return useMemo(() => {
        return (
            <div className={clsx(classes.container)}>
                <div className={clsx('icon')}>{icons['default']}</div>
                <div className={clsx('value')}>{value.split('.')[0]}</div>
                <div className={clsx('value_rest')}>
                    °C
                    <br />.{value.split('.')[1]}
                </div>
            </div>
        );
    }, [value]);
};

const OutsideTemperatureConfig = (props) => {
    const { systemId } = props;
    const classes = useStyles();
    const { state } = useBravoProxy();
    const value = getFunctionUserValue(state, systemId, '201');
    return useMemo(() => {
        return (
            <div className={clsx(classes.container)}>
                <div className={clsx('icon')}>{icons['outside_temperature']}</div>
                <div className={clsx('value')}>{value.split('.')[0]}</div>
                <div className={clsx('value_rest')}>
                    °C
                    <br />.{value.split('.')[1]}
                </div>
            </div>
        );
    }, [value]);
};

const HeatingTemperatureConfig = (props) => {
    const { systemId } = props;
    const classes = useStyles();
    const { state } = useBravoProxy();
    const value = getFunctionUserValue(state, systemId, '300');
    return useMemo(() => {
        return (
            <div className={clsx(classes.container)}>
                <div className={clsx('icon')}>{icons['heating_temperature']}</div>
                <div className={clsx('value')}>{value.split('.')[0]}</div>
                <div className={clsx('value_rest')}>
                    °C
                    <br />.{value.split('.')[1]}
                </div>
            </div>
        );
    }, [value]);
};

SecondInputConfiguration.propTypes = {};

export default SecondInputConfiguration;
