import React from 'react';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';
import { getFunctionUserValue } from '../../../../../../libs/functionHelper';
import { useBravoProxy } from '../../../../../../../BravoProxy/BravoProxy';

const useStyles = createUseStyles({
    container: {
        display: 'flex',
        height: 35,
        '& .value': {
            position: 'relative',
            top: 5,
            fontSize: 18,
        },
    },
});

const MinMaxVentilation = (props) => {
    const { systemId } = props;
    const classes = useStyles();
    const { state } = useBravoProxy();
    const minVentilation = getFunctionUserValue(state, systemId, '210');
    const maxVentilation = getFunctionUserValue(state, systemId, '211');

    return (
        <div className={clsx(classes.container)}>
            <div className={clsx('value')}>
                {minVentilation} - {maxVentilation} %
            </div>
        </div>
    );
};

MinMaxVentilation.propTypes = {};

export default MinMaxVentilation;
