import React, { useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';
import { getFunctionSystemValue, getFunctionUserValue } from '../../../../../../libs/functionHelper';
import { useBravoProxy } from '../../../../../../../BravoProxy/BravoProxy';

const icons = {
    // follow up
    follow_up: (
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 60 60'>
            <g id='OLD'>
                <polygon points='19.59 36 9 36 9 38 20.41 38 28.71 29.71 27.29 28.29 19.59 36' />
                <rect x='38' y='36' width='13' height='2' />
                <rect x='30.14' y='19.5' width='12.73' height='2' transform='translate(-3.81 31.81) rotate(-45)' />
                <path d='M25.5,25a12.51,12.51,0,0,0-9.72,4.64L11.68,26v8h9l-3.39-3A10.48,10.48,0,0,1,34.73,32.5l1.76-1A12.5,12.5,0,0,0,25.5,25Z' />
            </g>
        </svg>
    ),
    // pressure
    pressure: (
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 60 60'>
            <g id='OLD'>
                <path d='M46,29A16,16,0,1,0,30,45,16,16,0,0,0,46,29ZM30,43a14,14,0,0,1-12.5-7.74l2.72-1.13-.77-1.84-2.73,1.13A14.3,14.3,0,0,1,16.05,30H19V28H16.05a14.3,14.3,0,0,1,.67-3.42l2.73,1.13.77-1.84L17.5,22.74a13.49,13.49,0,0,1,1.93-2.89l2.08,2.08,1.42-1.42-2.08-2.08a13.49,13.49,0,0,1,2.89-1.93l1.13,2.72,1.84-.77-1.13-2.73A14.3,14.3,0,0,1,29,15.05V18h2V15.05a14.3,14.3,0,0,1,3.42.67l-1.13,2.73,1.84.77,1.13-2.72a13.49,13.49,0,0,1,2.89,1.93l-6.86,6.86,1.42,1.42,6.86-6.86a13.49,13.49,0,0,1,1.93,2.89l-2.72,1.13.77,1.84,2.73-1.13A14.3,14.3,0,0,1,44,28H41v2h3a14.3,14.3,0,0,1-.67,3.42l-2.73-1.13-.77,1.84,2.72,1.13A14,14,0,0,1,30,43Z' />
                <circle cx='30' cy='29' r='2' />
                <rect x='24.59' y='32' width='2.83' height='2' transform='translate(-15.72 28.05) rotate(-45)' />
            </g>
        </svg>
    ),
    // RH
    RH: (
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 60 60'>
            <g id='OLD'>
                <path d='M27.52,23.35c-.5-2.12-1.83-3.75-3.35-5.58A29,29,0,0,1,21.37,14a30,30,0,0,1-2.81,3.77C16.65,20.06,15,22.05,15,25.1c0,5.17,4.87,5.57,6.37,5.57.22,0,.52,0,.85,0a29.58,29.58,0,0,1,4.17-5.91Zm-7.71,4.42c-.34.5-2.06,1.28-3-1.07-1.18-2.91,1.71-5.65,1.71-5.65a6.66,6.66,0,0,0,.07,3.7C18.85,25.89,20.63,26.56,19.81,27.77Z' />
                <path d='M30.67,22.4a36.76,36.76,0,0,1-3.47,4.67c-2.36,2.83-4.4,5.27-4.4,9,0,6.38,6,6.88,7.86,6.88s7.87-.5,7.87-6.88c0-3.78-2-6.22-4.4-9A35.11,35.11,0,0,1,30.67,22.4Zm-1.93,17C28.32,40,26.21,41,25,38.09c-1.46-3.58,2.11-7,2.11-7a8.24,8.24,0,0,0,.08,4.57C27.56,37.1,29.75,37.93,28.74,39.42Z' />
                <path d='M39.65,15.86A24.35,24.35,0,0,1,37.3,19c-1.38,1.66-2.6,3.12-2.91,5.14l.45.54.75.91c-.2-2.07,1.66-3.84,1.66-3.84a5.71,5.71,0,0,0,.06,3.11c.23.95,1.73,1.52,1,2.53a1.32,1.32,0,0,1-1,.45,19.08,19.08,0,0,1,1.25,1.92,9.73,9.73,0,0,0,1.06.07c1.26,0,5.35-.33,5.35-4.67C45,22.62,43.61,21,42,19A25.22,25.22,0,0,1,39.65,15.86Z' />
            </g>
        </svg>
    ),
    // CO2
    CO2: (
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 60 60'>
            <g id='OLD'>
                <path d='M19.78,21.56a7.34,7.34,0,0,1,3.16.64l.68-2.54a8.91,8.91,0,0,0-3.94-.72c-5.09,0-9.47,3.49-9.47,10.6,0,6.41,3.52,10.13,9,10.13a10.71,10.71,0,0,0,4.34-.75L23,36.41a8.68,8.68,0,0,1-3.27.6c-3.73,0-6.23-2.52-6.23-7.65C13.52,24,16.31,21.56,19.78,21.56Z' />
                <path d='M32.94,18.9c-4.91,0-8.21,4.19-8.21,10.48s3,10.32,7.9,10.32c4.63,0,8.18-3.44,8.18-10.6C40.81,23.24,38,18.9,32.94,18.9ZM32.8,37.13c-3.15,0-4.77-3.52-4.77-7.77s1.53-7.89,4.77-7.89,4.71,3.94,4.71,7.75C37.51,33.43,36,37.13,32.8,37.13Z' />
                <path d='M45.41,41.84l.84-.83c1.83-1.71,3.57-3.46,3.57-5.66,0-1.71-1.17-3.38-3.92-3.38a5.94,5.94,0,0,0-3.6,1.16L43,34.76a4.05,4.05,0,0,1,2.32-.84,1.75,1.75,0,0,1,2,1.86c0,1.57-1.45,3.12-3.51,5.16L42.09,42.6v1.22H50V41.91h-4.6Z' />
            </g>
        </svg>
    ),
    // NH3
    NH3: (
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 60 60'>
            <g id='OLD'>
                <path d='M20.75,26.23c0,3.31.1,6,.32,8.77H21a71.88,71.88,0,0,0-3.09-6.68L13.07,19.2H9.78V39.41h2.87V32.34c0-3.73-.06-6.38-.22-8.93l.08,0a62.23,62.23,0,0,0,3.13,6.77l4.87,9.25h3.11V19.2H20.75Z' />
                <polygon points='38.32 27.54 30.87 27.54 30.87 19.2 27.72 19.2 27.72 39.41 30.87 39.41 30.87 30.28 38.32 30.28 38.32 39.41 41.47 39.41 41.47 19.2 38.32 19.2 38.32 27.54' />
                <path d='M49,37.55v-.06a2.94,2.94,0,0,0,2.2-2.69c0-1.49-1.14-2.83-3.65-2.83a6.67,6.67,0,0,0-3.32.86l.5,1.61a4.43,4.43,0,0,1,2.32-.7c1.12,0,1.69.59,1.69,1.38,0,1.23-1.5,1.71-2.53,1.71h-.56v1.54h.6c1.54,0,2.83.63,2.83,2,0,1-.86,1.86-2.3,1.86a5.49,5.49,0,0,1-2.55-.68l-.52,1.7A6.53,6.53,0,0,0,46.9,44c3,0,4.73-1.57,4.73-3.55A2.94,2.94,0,0,0,49,37.55Z' />
            </g>
        </svg>
    ),
};

const useStyles = createUseStyles({
    container: {
        display: 'flex',
        position: 'absolute',
        left: 0,
        bottom: 0,
        width: 180,
        height: 40,
        alignItems: 'center',
        '& .icon': {
            width: 40,
            height: 40,
            fill: '#FFFFFF',
        },
        '& .value': {
            paddingLeft: 10,
            fontSize: 18,
        },
    },
});

const IconLeft = (props) => {
    const { systemId } = props;
    const { state } = useBravoProxy();
    const extraConfigType = getFunctionSystemValue(state, systemId, '102');

    return useMemo(() => {
        switch (extraConfigType) {
            case '1':
                return <FollowUpPositionControl {...props} />;
            case '2':
                return <PressureControl {...props} />;
            case '3':
                return <RHControl {...props} />;
            case '4':
                return <CO2Control {...props} />;
            case '5':
                return <NH3Control {...props} />;
            default:
                return null;
        }
    }, [extraConfigType]);
};

const FollowUpPositionControl = (props) => {
    const { systemId } = props;
    const classes = useStyles();
    const { state } = useBravoProxy();
    const value = getFunctionUserValue(state, systemId, '1502');
    return useMemo(() => {
        return (
            <div className={clsx(classes.container)}>
                <div className='icon'>{icons.follow_up}</div>
                <div className='value'>{value} %</div>
            </div>
        );
    }, [value]);
};

const PressureControl = (props) => {
    const { systemId } = props;
    const classes = useStyles();
    const { state } = useBravoProxy();
    const value = getFunctionUserValue(state, systemId, '1002');
    return useMemo(() => {
        return (
            <div className={clsx(classes.container)}>
                <div className='icon'>{icons.pressure}</div>
                <div className='value'>{value} Pa</div>
            </div>
        );
    }, [value]);
};

const RHControl = (props) => {
    const { systemId } = props;
    const classes = useStyles();
    const { state } = useBravoProxy();
    const value = getFunctionUserValue(state, systemId, '1100');
    return useMemo(() => {
        return (
            <div className={clsx(classes.container)}>
                <div className='icon'>{icons.RH}</div>
                <div className='value'>{value} %</div>
            </div>
        );
    }, [value]);
};

const CO2Control = (props) => {
    const { systemId } = props;
    const classes = useStyles();
    const { state } = useBravoProxy();
    const value = getFunctionUserValue(state, systemId, '1201');
    return useMemo(() => {
        return (
            <div className={clsx(classes.container)}>
                <div className='icon'>{icons.CO2}</div>
                <div className='value'>{value} ppm</div>
            </div>
        );
    }, [value]);
};

const NH3Control = (props) => {
    const { systemId } = props;
    const classes = useStyles();
    const { state } = useBravoProxy();
    const value = getFunctionUserValue(state, systemId, '1301');
    return useMemo(() => {
        return (
            <div className={clsx(classes.container)}>
                <div className='icon'>{icons.NH3}</div>
                <div className='value'>{value} ppm</div>
            </div>
        );
    }, [value]);
};

IconLeft.propTypes = {};

export default IconLeft;
