import React, { useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';
import { getFunctionSystemValue } from '../../../../../../libs/functionHelper';
import { useBravoProxy } from '../../../../../../../BravoProxy/BravoProxy';

const icons = {
    cooling: (
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 60 60'>
            <g id='OLD'>
                <polygon points='44.52 19.37 39.26 22.27 40.04 17.36 38.56 17.12 37.59 23.18 31 26.81 31 19.63 36.04 15.79 35.13 14.6 31 17.75 31 12 29 12 29 17.75 24.87 14.6 23.96 15.79 29 19.63 29 26.81 22.41 23.18 21.44 17.12 19.96 17.36 20.74 22.27 15.48 19.37 14.52 21.13 19.67 23.96 14.85 25.75 15.37 27.16 21.41 24.91 27.93 28.5 21.41 32.09 15.37 29.84 14.85 31.25 19.67 33.04 14.52 35.87 15.48 37.63 20.74 34.73 19.96 39.65 21.44 39.88 22.41 33.82 29 30.19 29 37.37 23.96 41.21 24.87 42.4 29 39.25 29 45 31 45 31 39.25 35.13 42.4 36.04 41.21 31 37.37 31 30.19 37.59 33.82 38.56 39.88 40.04 39.65 39.26 34.73 44.52 37.63 45.48 35.87 40.33 33.04 45.15 31.25 44.63 29.84 38.59 32.09 32.07 28.5 38.59 24.91 44.63 27.16 45.15 25.75 40.33 23.96 45.48 21.13 44.52 19.37' />
            </g>
        </svg>
    ),
    heating: (
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 60 60'>
            <g id='OLD'>
                <path d='M43,34.57a8.83,8.83,0,0,0-3.22-7.47c-4.22-3.74-7-8.66-6.49-14A14.43,14.43,0,0,1,33.6,11a24.16,24.16,0,0,0-5.73,12.8c.27-2.34-1.87-4.49-2.34-6.86A16,16,0,0,1,20.3,27.06a9.15,9.15,0,0,0-3.26,6c-.42,4.32,2.71,8.2,7.46,9.94a4,4,0,0,1-.71-2.39A4.62,4.62,0,0,1,25.86,37,9,9,0,0,0,30,30.48s0-.07,0-.11h0s0,.07,0,.11c.16,2.61,1.87,4.89,4.19,6.53a4.69,4.69,0,0,1,2.11,3.55A4.13,4.13,0,0,1,35.59,43C39.66,41.45,42.6,38.33,43,34.57Z' />
            </g>
        </svg>
    ),
};

const useStyles = createUseStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: 40,
        height: 80,
        '& .cooling, .heating': {
            width: 40,
            height: 40,
            opacity: 0.4,
            fill: 'white',
            '&.active': {
                opacity: 1,
            },
        },
    },
});

const IconRight = (props) => {
    const classes = useStyles();
    return (
        <div className={clsx(classes.container)}>
            <Heating {...props} />
            <Cooling {...props} />
        </div>
    );
};

const Heating = (props) => {
    const { systemId } = props;
    const { state } = useBravoProxy();
    const configured = getFunctionSystemValue(state, systemId, '304');
    const enabled = getFunctionSystemValue(state, systemId, '303');
    return useMemo(() => {
        if (configured === '1') {
            return <div className={clsx('heating', { active: enabled === '1' })}>{icons.heating}</div>;
        }
        return null;
    }, [configured, enabled]);
};

const Cooling = (props) => {
    const { systemId } = props;
    const { state } = useBravoProxy();
    const configured = getFunctionSystemValue(state, systemId, '2102');
    const enabled = getFunctionSystemValue(state, systemId, '2101');
    return useMemo(() => {
        if (configured === '1') {
            return <div className={clsx('cooling', { active: enabled === '1' })}>{icons.cooling}</div>;
        }
        return null;
    }, [configured, enabled]);
};

IconRight.propTypes = {};

export default IconRight;
