import React, { useMemo } from 'react';
import AlarmIcon from '../AlarmIcon';
import ProductVisual from '../ProductVisual';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';
import { getHasConfigState, getIsOnlineState, isProductSupported } from '../../libs/deviceHelper';
import { useBravoProxy } from '../../../BravoProxy/BravoProxy';

const icons = {
    offline: (
        <svg viewBox='0 0 24 24'>
            <path
                fill='currentColor'
                d='M4,1C2.89,1 2,1.89 2,3V7C2,8.11 2.89,9 4,9H1V11H13V9H10C11.11,9 12,8.11 12,7V3C12,1.89 11.11,1 10,1H4M4,3H10V7H4V3M14,13C12.89,13 12,13.89 12,15V19C12,20.11 12.89,21 14,21H11V23H23V21H20C21.11,21 22,20.11 22,19V15C22,13.89 21.11,13 20,13H14M3.88,13.46L2.46,14.88L4.59,17L2.46,19.12L3.88,20.54L6,18.41L8.12,20.54L9.54,19.12L7.41,17L9.54,14.88L8.12,13.46L6,15.59L3.88,13.46M14,15H20V19H14V15Z'
            />
        </svg>
    ),
    no_config: (
        <svg viewBox='0 0 24 24'>
            <path
                fill='currentColor'
                d='M22.12 21.46L2.4 1.73L1.12 3L4 5.87L2.34 8.73C2.21 8.95 2.27 9.22 2.46 9.37L4.57 11C4.53 11.34 4.5 11.67 4.5 12S4.53 12.65 4.57 12.97L2.46 14.63C2.27 14.78 2.21 15.05 2.34 15.27L4.34 18.73C4.46 18.95 4.73 19.03 4.95 18.95L7.44 17.94C7.96 18.34 8.5 18.68 9.13 18.93L9.5 21.58C9.54 21.82 9.75 22 10 22H14C14.25 22 14.46 21.82 14.5 21.58L14.87 18.93C15.38 18.72 15.83 18.45 16.26 18.14L20.85 22.73L22.12 21.46M12 15.5C10.07 15.5 8.5 13.93 8.5 12C8.5 11.5 8.62 11.08 8.79 10.67L13.33 15.21C12.92 15.39 12.5 15.5 12 15.5M11.74 8.53L8.56 5.35C8.75 5.25 8.93 5.15 9.13 5.07L9.5 2.42C9.54 2.18 9.75 2 10 2H14C14.25 2 14.46 2.18 14.5 2.42L14.87 5.07C15.5 5.32 16.04 5.66 16.56 6.05L19.05 5.05C19.27 4.96 19.54 5.05 19.66 5.27L21.66 8.73C21.78 8.95 21.73 9.22 21.54 9.37L19.43 11C19.47 11.34 19.5 11.67 19.5 12S19.47 12.65 19.43 12.97L21.54 14.63C21.73 14.78 21.78 15.05 21.66 15.27L20.5 17.29L15.47 12.26C15.5 12.18 15.5 12.09 15.5 12C15.5 10.07 13.93 8.5 12 8.5C11.91 8.5 11.83 8.5 11.74 8.53Z'
            />
        </svg>
    ),
};

const useStyles = createUseStyles({
    error: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        '& .icon': {
            width: 40,
            height: 40,
        },
        '& .text': {
            fontSize: 14,
            marginTop: 10,
        },
    },
});

const VisualContainer = (props) => {
    const { systemId } = props;
    const classes = useStyles(props);
    const { state } = useBravoProxy();

    // dont show anything when device does not exists in state
    if (!state[systemId]) {
        return null;
    }

    const isOnline = getIsOnlineState(state, systemId);
    const isSupported = isProductSupported(state, systemId);
    const hasConfig = getHasConfigState(state, systemId);

    return useMemo(() => {
        if (!isSupported) {
            return (
                <div className={clsx(classes.error)}>
                    <div className='icon'>{icons.no_config}</div>
                    <div className='text'>no visual</div>
                </div>
            );
        } else if (!isOnline) {
            return (
                <div className={clsx(classes.error)}>
                    <div className='icon'>{icons.offline}</div>
                    <div className='text'>device offline</div>
                </div>
            );
        } else if (isOnline && !hasConfig) {
            return (
                <div className={clsx(classes.error)}>
                    <div className='icon'>{icons.no_config}</div>
                    <div className='text'>no config</div>
                </div>
            );
        } else {
            return (
                <React.Fragment>
                    <AlarmIcon {...props} />
                    <ProductVisual {...props} />
                </React.Fragment>
            );
        }
    }, [isOnline, hasConfig]);
};

VisualContainer.propTypes = {};

export default VisualContainer;
