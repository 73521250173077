import React from 'react';
import { ABBIDCC, BBVK, MFBT } from './products';

export const PRODUCTS = [
    {product: 'MFBT', render: (props) => <MFBT {...props} />},
    {product: 'ABBIDCC', render: (props) => <ABBIDCC {...props} />},
    {product: 'BBVK', render: (props) => <BBVK {...props} />},
    {product: 'MFBT', render: (props) => <MFBT {...props} />},
]

export const SUPPORTED_PRODUCTS = PRODUCTS.map((prod) => prod.product);

