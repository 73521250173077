import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { getProduct } from '../../libs/productHelper';
import { useBravoProxy } from '../../../BravoProxy/BravoProxy';
import { PRODUCTS } from './constants';


const ProductVisual = (props) => {
    const { systemId } = props;
    const { state } = useBravoProxy();
    const product = getProduct(state, systemId);

    return useMemo(() => {
        const prod = PRODUCTS.find(prod=>prod.product === product);

        if(prod === undefined) return <></>;

        return prod.render(props);
    }, [product]);
};

ProductVisual.propTypes = {};

export default ProductVisual;
