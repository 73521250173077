import { words } from 'lodash';

export const getExpressionIds = (expression) => {
    let expressionIds = [];

    words(expression, /[^{\\}]+(?=})/g).forEach((functionId) => {
        if (!expressionIds.includes(functionId)) {
            expressionIds.push(functionId);
        }
    });

    return expressionIds;
};
