import React, { useContext, useEffect, useReducer, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { loadDevicesData } from './helpers/deviceData';
import { sendMessage, subscribeToDevice } from './helpers/ws';
import clsx from 'clsx';
import { createUseStyles } from 'react-jss';
import produce from 'immer';
import { BravoProxyConfig } from '../../config';

const SET_INITIAL_STATE = 'SET_INITIAL_STATE';
const SET_FUNCTION_VALUES = 'SET_FUNCTION_VALUES';
const SET_DEVICE_ONLINE_STATE = 'SET_DEVICE_ONLINE_STATE';
const SUBSCRIBE_TO_DEVICE_WEBSOCKET = 'SUBSCRIBE_TO_DEVICE_WEBSOCKET';

let wsClient = null;

export const setFunctionValue = (systemId, data) => ({
    type: SET_FUNCTION_VALUES,
    systemId,
    data,
});

export const setDeviceOnlineState = (systemId, data) => ({
    type: SET_DEVICE_ONLINE_STATE,
    data,
    systemId
});

export const setInitialState = (data) => ({
    type: SET_INITIAL_STATE,
    data,
});

export const subscribeToDeviceWebSocket = (systemId) => ({
    type: SUBSCRIBE_TO_DEVICE_WEBSOCKET,
    systemId,
});

const useStyles = createUseStyles({
    container: {
        '& .mf_bravo_visual_AZptXa *': {
            lineHeight: 1,
            WebkitFontSmoothing: 'antialiased !important',
            MozOsxFontSmoothing: 'grayscale !important',
        },
    },
});

const initialState = {};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SUBSCRIBE_TO_DEVICE_WEBSOCKET:
            const { systemId } = action;
            subscribeToDevice(wsClient, systemId);
            return produce(state, (draft) => {
                draft[systemId].subscribed = true;
            });
        case SET_INITIAL_STATE:
            return action.data;
        case SET_FUNCTION_VALUES:
            return produce(state, (draft) => {
                const { systemId, data } = action;
                Object.entries(data).forEach((item) => {
                    const functionId = item[0];
                    const functionValue = item[1];
                    draft[systemId].state[functionId] = functionValue;
                });
            });
        case SET_DEVICE_ONLINE_STATE:
            return produce(state, (draft) => {
                const { systemId, data } = action;
                draft[systemId].online = data.online;
            });
        default:
            return state;
    }
};

const BravoProxyContext = React.createContext();

export const useBravoProxy = () => {
    return useContext(BravoProxyContext);
};

const BravoProxy = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [wsOpen, setWsOpen] = useState(false);
    const { accessToken, devices } = props;
    const keepAlive = useRef();
    const classes = useStyles();
    const [state, dispatch] = useReducer(reducer, initialState);

    const connectWebSocket = (accessToken) => {
        wsClient = new WebSocket(`${BravoProxyConfig.websocketHost}/device`, [`at-${accessToken}`]);
        wsClient.onopen = () => {
            setWsOpen(true);
        };
        wsClient.onmessage = (event) => {
            const message = JSON.parse(event.data);
            if (message.systemId !== undefined) {
                const { systemId, type, data } = message;
                switch (type) {
                    case 'online_state':
                        dispatch(setDeviceOnlineState(systemId, data));
                        break;
                    case 'function_value_changed':
                        dispatch(setFunctionValue(systemId, data));
                        break;
                }
            }
        };
        wsClient.onclose = () => {
            console.info('ws disconnected');
            setTimeout(() => {
                connectWebSocket(accessToken);
            }, 5000);
        };
    };

    useEffect(() => {
        setIsLoading(true);
        const loadData = async () => {
            const data = await loadDevicesData(accessToken, devices);
            // set initial state
            dispatch(setInitialState(data));

            setIsLoading(false);
        };
        loadData();
    }, [accessToken, devices]);

    useEffect(() => {
        if (accessToken) {
            connectWebSocket(accessToken);

            // keep websocket connection alive
            keepAlive.current = setInterval(() => {
                sendMessage(wsClient, {
                    type: 'ping',
                    data: null,
                });
            }, 10000);
        }
        return () => {
            if (wsClient) {
                wsClient.close();
            }
            if (keepAlive.current) {
                clearInterval(keepAlive.current);
            }
        };
    }, [accessToken]);

    if (isLoading || !wsOpen) {
        return props.loader ? props.loader : 'Loading';
    }

    return (
        <BravoProxyContext.Provider value={{ state, dispatch }}>
            <div className={clsx(classes.container, 'mf_bravo_proxy')}>{props.children}</div>
        </BravoProxyContext.Provider>
    );
};

BravoProxy.propTypes = {
    accessToken: PropTypes.string.isRequired,
    devices: PropTypes.arrayOf(
        PropTypes.shape({
            systemId: PropTypes.string.isRequired,
            version: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            product: PropTypes.string.isRequired,
            online: PropTypes.bool,
        })
    ).isRequired,
};

export default BravoProxy;
