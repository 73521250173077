import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { createUseStyles } from 'react-jss';
import ActualVentilation from '../ActualVentilation';
import THI from '../THI';

const useStyles = createUseStyles({
    container: {
        display: 'flex',
        opacity: 0.4,
        height: 35,
    },
    left: {
        flex: 1,
    },
    right: {
        flex: 1,
    },
});

const ZoneBottom = (props) => {
    const classes = useStyles();
    return (
        <div className={clsx(classes.container)}>
            <div className={clsx(classes.left)}>
                <ActualVentilation {...props} />
            </div>
            <div className={clsx(classes.right)}>
                <THI {...props} />
            </div>
        </div>
    );
};

ZoneBottom.propTypes = {};

export default ZoneBottom;
