import React, { useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';
import { evaluateExpression, getFunctionSystemValue } from '../../../../../../libs/functionHelper';
import { useBravoProxy } from '../../../../../../../BravoProxy/BravoProxy';

const icons = {
    soaking_on: (
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 42 24.8'>
            <g id='art'>
                <polygon points='2,3 4,3 4,5 12,5 12,3 17,3 17,5 25,5 25,3 30,3 30,5 38,5 38,3 40,3 42,0 0,0' />
                <path d='M10.4,24.7c1.8-0.1,2.8-2.1,2.8-3.9c0.5-1.7-3.4-10-3.4-10.9c0,2.9-0.5,5.6-1.5,6.9c-1.3,2-2.5,3.8-2.3,5.6C6.4,23.9,7.4,25,10.4,24.7z' />
                <path d='M31.1,16.7c-1.3,2-2.3,3.8-2.2,5.6c0.2,1.5,1.2,2.7,4.2,2.4c1.9-0.1,2.9-2.1,2.9-3.9c0.5-1.7-3.3-10-3.2-10.9C32.6,12.8,32.1,15.5,31.1,16.7z' />
                <path d='M22.2,20.8c1.8-0.1,2.8-2.1,2.8-3.9c0.5-1.8-3.3-10-3.3-10.9c0,2.9-0.5,5.6-1.5,6.9c-1.3,2-2.5,3.8-2.2,5.6C18.2,20,19.2,21.1,22.2,20.8z' />
            </g>
        </svg>
    ),
    soaking_off: (
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 42 24.8'>
            <g id='art'>
                <polygon points='2,3 4,3 4,5 12,5 12,3 17,3 17,5 25,5 25,3 30,3 30,5 38,5 38,3 40,3 42,0 0,0' />
            </g>
        </svg>
    ),
    spraying_on: (
        <svg xmlns='http://www.w3.org/2000/svg'>
            <g id='art'>
                <path d='M18.43,9a3.87,3.87,0,0,0,3.7,4h3.3v-.41L29,14.51l1-1.76-4.22-2.29-.31.54V8.28l5-2.72-1-1.76-4,2.2V5h-3.3A3.87,3.87,0,0,0,18.43,9Zm3.7-2h1.3v4h-1.3a1.86,1.86,0,0,1-1.7-2A1.86,1.86,0,0,1,22.13,7Z' />
                <path d='M11.43,30c0-2-3-5.2-5.35-6.46a7.71,7.71,0,0,0,.18-10.9l-.18-.18C8.43,11.2,11.43,8,11.43,6c0-2.45-3.55-6-5-6C5.53,0,4,.36,3,2.79A13.56,13.56,0,0,0,2.24,7.6,13.91,13.91,0,0,0,2.62,11H2.43a5.28,5.28,0,0,0-2.4.58l.91,1.78A3.21,3.21,0,0,1,2.43,13c2.21,0,4,2.24,4,5s-1.79,5-4,5a3.23,3.23,0,0,1-1.52-.38L0,24.4a5.14,5.14,0,0,0,2.43.6h.19a14,14,0,0,0-.38,3.41A13.56,13.56,0,0,0,3,33.22C4,35.64,5.53,36,6.43,36,7.88,36,11.43,32.45,11.43,30ZM5,3.24C5.5,2.14,6,2,6.29,2c.79.33,3.14,2.7,3.14,4S6.07,10.5,4.7,11A12.7,12.7,0,0,1,5,3.24ZM6.29,34C6,34,5.5,33.86,5,32.76a12.77,12.77,0,0,1-.3-7.71c1.37.45,4.73,3.66,4.73,5S7.08,33.67,6.29,34Z' />
                <path d='M13.35,35.39A47.82,47.82,0,0,0,16.43,18,47.82,47.82,0,0,0,13.35.61l-1.84.78A46.81,46.81,0,0,1,14.43,18a46.81,46.81,0,0,1-2.92,16.61Z' />
                <rect x='31.49' y='1.34' width='5.59' height='2' transform='translate(3.03 16.62) rotate(-28.47)' />
                <rect x='34.11' y='4.55' width='4.03' height='2' transform='translate(0.83 15.04) rotate(-23.78)' />
                <rect x='28.22' y='7.18' width='4.8' height='2' transform='translate(-0.67 13.22) rotate(-24.11)' />
                <rect x='36.84' y='12.06' width='2' height='3.34' transform='translate(13.1 45.49) rotate(-72.03)' />
                <rect x='31.46' y='9.72' width='2' height='4.25' transform='translate(11.08 38.98) rotate(-71.82)' />
                <polygon points='37.95 17.13 32.28 14.01 31.32 15.76 34.39 17.45 31.29 19.25 32.3 20.98 35.93 18.86 35.45 18.03 36.98 18.88 37.95 17.13' />
                <path d='M25.43,29l4.07,2.2,1-1.76-5-2.72V24.05l.27.49L30,22.25l-1-1.76-3.56,1.92V22h-3.3a4,4,0,1,0,0,8h3.3Zm-2-1h-1.3a2,2,0,1,1,0-4h1.3Z' />
                <rect x='33.28' y='29.86' width='2' height='5.59' transform='translate(-10.76 47.25) rotate(-61.56)' />
                <rect x='35.13' y='27.44' width='2' height='4.03' transform='translate(-5.39 50.64) rotate(-66.22)' />
                <rect x='29.62' y='24.41' width='2' height='4.8' transform='translate(-6.36 43.81) rotate(-65.89)' />
                <rect x='36.17' y='20.28' width='3.34' height='2' transform='translate(-4.72 12.71) rotate(-17.97)' />
                <rect x='30.32' y='22.17' width='4.25' height='2' transform='translate(-5.61 11.28) rotate(-18.18)' />
            </g>
        </svg>
    ),
    spraying_off: (
        <svg xmlns='http://www.w3.org/2000/svg'>
            <g id='art'>
                <path
                    d='M11.43,6c0-2.45-3.55-6-5-6C5.53,0,4,.36,3,2.79A13.56,13.56,0,0,0,2.24,7.6,13.91,13.91,0,0,0,2.62,11H2.43a5.28,5.28,0,0,0-2.4.58l.91,1.78A3.21,3.21,0,0,1,2.43,13c2.21,0,4,2.24,4,5s-1.79,5-4,5a3.23,3.23,0,0,1-1.52-.38L0,24.4a5.14,5.14,0,0,0,2.43.6h.19a14,14,0,0,0-.38,3.41A13.56,13.56,0,0,0,3,33.22C4,35.64,5.53,36,6.43,36c1.5,0,5.05-3.55,5.05-6,0-2-3-5.2-5.35-6.46a7.71,7.71,0,0,0,.18-10.9l-.18-.18C8.43,11.2,11.43,8,11.43,6Zm-2,24c0,1.3-2.35,3.67-3.14,4C6,34,5.5,33.86,5,32.76a12.77,12.77,0,0,1-.3-7.71C6.07,25.5,9.43,28.71,9.43,30ZM4.7,11A12.77,12.77,0,0,1,5,3.29c.5-1.1,1-1.22,1.29-1.24.79.33,3.14,2.7,3.14,4S6.07,10.5,4.7,11Z'
                    transform='translate(0 0)'
                />
                <path
                    d='M13.35,35.39A47.82,47.82,0,0,0,16.43,18,47.82,47.82,0,0,0,13.35.61l-1.84.78A46.81,46.81,0,0,1,14.43,18a46.81,46.81,0,0,1-2.92,16.61Z'
                    transform='translate(0 0)'
                />
                <path
                    d='M25.43,5h-3.3a3.87,3.87,0,0,0-3.7,4,3.87,3.87,0,0,0,3.7,4h3.3Zm-2,6h-1.3a1.86,1.86,0,0,1-1.7-2,1.86,1.86,0,0,1,1.7-2h1.3Z'
                    transform='translate(0 0)'
                />
                <path d='M25.43,22h-3.3a4,4,0,1,0,0,8h3.3Zm-2,6h-1.3a2,2,0,1,1,0-4h1.3Z' transform='translate(0 0)' />
            </g>
        </svg>
    ),
};

const useStyles = createUseStyles({
    container: {
        position: 'relative',
        right: 0,
        top: 8,
        opacity: 0.4,
        '& .icon': {
            fill: 'white',
        },
        '&.soaking .icon': {
            position: 'absolute',
            right: 0,
            width: 35,
        },
        '&.spraying': {
            height: 40,
            '& .icon': {
                position: 'absolute',
                width: 30,
                height: 30,
            },
        },
    },
    icon: {},
});

const ExtraControl = (props) => {
    const { zoneConfig, systemId } = props;
    const { state } = useBravoProxy();
    const mode = getFunctionSystemValue(state, systemId, zoneConfig.extraControl.mode);

    return useMemo(() => {
        switch (mode) {
            case '1':
                return <SoakingControl {...props} />;
            case '2':
                return <SprayingControl {...props} />;
            default:
                return null;
        }
    }, [mode]);
};

const SoakingControl = (props) => {
    const { zoneConfig, systemId } = props;
    const classes = useStyles();
    const { state } = useBravoProxy();
    const enabled = evaluateExpression(state, systemId, zoneConfig.extraControl.activeExpression);
    return useMemo(() => {
        return (
            <div className={clsx(classes.container, 'soaking')}>
                <div className='icon'>{enabled ? icons.soaking_on : icons.soaking_off}</div>
            </div>
        );
    }, [enabled]);
};

const SprayingControl = (props) => {
    const { zoneConfig, systemId } = props;
    const classes = useStyles();
    const { state } = useBravoProxy();
    const enabled = evaluateExpression(state, systemId, zoneConfig.extraControl.activeExpression);
    return useMemo(() => {
        return (
            <div className={clsx(classes.container, 'spraying')}>
                <div className='icon'>{enabled ? icons.spraying_on : icons.spraying_off}</div>
            </div>
        );
    }, [enabled]);
};

ExtraControl.propTypes = {};

export default ExtraControl;
