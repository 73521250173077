import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';
import ActualTemperature from '../ActualTemperature/ActualTemperature';
import ExtraControl from '../ExtraControl';

const icons = {
    ventilator: (
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 63.99'>
            <path
                d='M64,47.88c-.15,3.26-3.09,6.65-6.35,10.19s-6,4-7.68,2.51c-1.4-1.28-10.63-15.64-13.5-20.41A9.32,9.32,0,0,1,32,41.34a3.41,3.41,0,0,1-.46-.05c-.27.72-.63,1.53-1.06,2.45C21.27,62.63,22.16,64.24,16.1,64c-3.25-.15-6.64-3.1-10.19-6.35s-4-6-2.51-7.68c1.28-1.4,15.48-10.54,20.33-13.45a9.32,9.32,0,0,1-1.24-4.59c0-.16,0-.31.05-.47-.69-.26-1.45-.6-2.3-1C1.36,21.27-.24,22.16,0,16.12c.16-3.26,3.1-6.65,6.35-10.19s6.06-4,7.68-2.51c1.4,1.26,10.44,15.31,13.4,20.23A9.33,9.33,0,0,1,32,22.46c.22,0,.41.06.62.07.26-.68.58-1.42,1-2.27C42.73,1.36,41.84-.24,47.88,0c3.25.15,6.65,3.09,10.19,6.34s4,6.06,2.51,7.68C59.3,15.46,45.12,24.58,40.26,27.51a9.34,9.34,0,0,1,1.13,4.4c0,.21,0,.4-.06.6.7.27,1.51.63,2.41,1.06,18.9,9.16,20.5,8.27,20.23,14.31Z'
                transform='translate(0 0)'
            />
        </svg>
    ),
};

const useStyles = createUseStyles({
    container: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        height: 45,
    },
    left: {
        position: 'relative',
        top: 0,
        left: 0,
        width: 115,
    },
    right: {
        position: 'relative',
        top: 0,
        right: 0,
        width: 45,
    },
});

const ZoneHeader = (props) => {
    const classes = useStyles();

    return (
        <div className={clsx(classes.container)}>
            <div className={clsx(classes.left)}>
                <ActualTemperature {...props} />
            </div>
            <div className={clsx(classes.right)}>
                <ExtraControl {...props} />
            </div>
        </div>
    );
};

ZoneHeader.propTypes = {};

export default ZoneHeader;
