import { clone } from 'lodash';
import { convertToUser } from 'mfbravolib';
import { getExpressionIds } from './expressionHelper';
import { isString } from '../../BravoProxy/helpers/util';

export const getFunctionInfo = (state, systemId, functionId) => state[systemId].config.functions[functionId];

export const getFunctionSystemValue = (state, systemId, functionId) => state[systemId].state[functionId];

export const getFunctionUserValue = (state, systemId, functionId) => {
    const functionInfo = getFunctionInfo(state, systemId, functionId);
    const systemValue = getFunctionSystemValue(state, systemId, functionId);
    const { unitMetric, conversionMetric, unitFormatMetric, unitImperial } = functionInfo;
    return convertToUser(systemValue, unitMetric, conversionMetric, unitFormatMetric, unitImperial, false);
};

export const evaluateExpression = (state, systemId, expression, expressionIds = [], defaultExpressionResult = true) => {
    let result = defaultExpressionResult;

    if (expression && isString(expression)) {
        // create clone
        let evalExpression = clone(expression);

        // in case there are no expressionIds then fill them
        if (!Array.isArray(expressionIds) || expressionIds.length === 0) {
            expressionIds = getExpressionIds(evalExpression);
        }

        // now walk through all expression id's and create an expression to evaluate
        expressionIds.forEach((expressionId) => {
            const systemValue = getFunctionSystemValue(state, systemId, expressionId);

            if (systemValue) {
                evalExpression = evalExpression.split(`{${expressionId}}`).join(systemValue);
            }
        });

        // execute javascript eval
        try {
            result = eval(evalExpression);
        } catch (error) {}
    }

    return result;
};
