import React, { useMemo } from 'react';
import ZoneHeader from '../ZoneHeader';
import ZoneBottom from '../ZoneBottom';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';
import { getFunctionUserValue } from '../../../../../../libs/functionHelper';
import { useBravoProxy } from '../../../../../../../BravoProxy/BravoProxy';

const useStyles = createUseStyles({
    container: {
        '&.disabled': {
            backgroundColor: 'rgba(245, 166, 35, 0.6)',
        },
    },
});

const Zone = (props) => {
    const { zoneConfig, systemId } = props;
    const classes = useStyles();
    const { state } = useBravoProxy();
    const zoneActive = getFunctionUserValue(state, systemId, zoneConfig.active);
    return useMemo(() => {
        return (
            <div className={clsx(classes.container, { disabled: zoneActive !== '1' })}>
                <ZoneHeader {...props} />
                <ZoneBottom {...props} />
            </div>
        );
    }, [zoneActive]);
};

Zone.propTypes = {};

export default Zone;
