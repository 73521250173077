import React, { useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';
import { getFunctionUserValue } from '../../../../../../libs/functionHelper';
import { useBravoProxy } from '../../../../../../../BravoProxy/BravoProxy';

const icons = {
    temperature: (
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 29.01'>
            <path d='M9,18.18V9H7v9.18a3,3,0,1,0,2,0Z' />
            <path d='M13,0H3V14.76A8,8,0,1,0,14.25,16,8.62,8.62,0,0,0,13,14.76V12h3V10H13V8h3V6H13V4h3V2H13Zm1,21a6,6,0,1,1-9.43-4.92l.43-.3V2h6V15.78l.43.3A6,6,0,0,1,14,21Z' />
        </svg>
    ),
};

const useStyles = createUseStyles({
    container: {
        position: 'relative',
        top: -3,
        display: 'flex',
        flexDirection: 'row',
        height: 45,
        // backgroundColor: 'black',
    },
    icon: {
        position: 'absolute',
        top: 10,
        fill: '#FFFFFF',
        width: 20,
    },
    value: {
        position: 'absolute',
        right: 24,
        bottom: -5,
        marginLeft: 5,
        fontSize: 50,
    },
    value_rest: {
        position: 'absolute',
        right: 7,
        bottom: -2,
        marginLeft: 5,
        lineHeight: 1,
        '& .unit': {
            fontSize: 18,
        },
        '& .decimal': {
            fontSize: 22,
        },
    },
});

const ActualTemperature = (props) => {
    const { systemId } = props;
    const classes = useStyles();
    const { state } = useBravoProxy();
    const actualTemperature = getFunctionUserValue(state, systemId, '200');
    return useMemo(() => {
        return (
            <div className={clsx(classes.container)}>
                <div className={clsx(classes.icon)}>{icons.temperature}</div>
                <div className={clsx(classes.value)}>{actualTemperature.split('.')[0]}</div>
                <div className={clsx(classes.value_rest)}>
                    <span className='unit'>°C</span>
                    <br />
                    <span className='decimal'>.{actualTemperature.split('.')[1]}</span>
                </div>
            </div>
        );
    }, [actualTemperature]);
};

ActualTemperature.propTypes = {};

export default ActualTemperature;
