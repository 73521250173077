import React, { useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';
import { getFunctionUserValue } from '../../../../../../libs/functionHelper';
import { useBravoProxy } from '../../../../../../../BravoProxy/BravoProxy';

const icons = {
    ventilator: (
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'>
            <g id='art'>
                <path d='M16,32c8.8,0,16-7.2,16-16S24.8,0,16,0S0,7.2,0,16S7.2,32,16,32z M12.1,17c0.3,1,0.9,1.9,1.9,2.4c-1.1,0.6-2.2,1-3.4,1.2 c-0.8,0.2-1.6,0.4-2.3,0.7c-1.3,0.7-2.5,1.7-3.5,2.9c-0.5-0.7-1-1.5-1.4-2.3L12.1,17z M22.7,18.4c0.6,0.6,1.2,1.2,1.8,1.7 c1.3,0.8,2.8,1.3,4.3,1.5c-0.3,0.8-0.8,1.6-1.3,2.3l-8.7-5c0.8-0.8,1.2-1.8,1.2-2.9C21,16.7,21.9,17.5,22.7,18.4L22.7,18.4z M18,16 c0,1.1-0.9,2-2,2s-2-0.9-2-2s0.9-2,2-2S18,14.9,18,16z M16,30c-3.8,0-7.4-1.5-10-4.2c0.8-1,1.9-1.9,3-2.6c0.7-0.2,1.4-0.5,2.1-0.6 c1.5-0.3,3-0.9,4.3-1.7l1.1-0.9h0.1l9.6,5.5C23.6,28.4,19.9,30,16,30z M30,16c0,1.3-0.2,2.5-0.5,3.7c-1.3-0.2-2.6-0.6-3.7-1.2 c-0.6-0.5-1.1-1-1.6-1.5c-1-1.2-2.2-2.2-3.6-2.9l-1.4-0.5l-0.1-0.1V2.4C25.5,3.8,30,9.5,30,16z M17.1,2.1v10.1 C16.7,12.1,16.4,12,16,12c-0.7,0-1.4,0.2-2,0.5c0.1-1.2,0.3-2.4,0.7-3.5c0.2-0.8,0.4-1.6,0.5-2.4c0-1.5-0.2-3.1-0.8-4.5 C15,2.1,15.5,2,16,2C16.4,2,16.7,2,17.1,2.1z M12.5,2.5C13,3.7,13.2,5,13.3,6.3C13.2,7,13,7.7,12.8,8.4c-0.5,1.5-0.8,3-0.7,4.6 l0.2,1.4c0,0,0,0.1,0,0.1l-9.6,5.6C0.4,12.8,4.5,4.9,11.9,2.6C12.1,2.6,12.3,2.5,12.5,2.5L12.5,2.5z' />{' '}
            </g>
        </svg>
    ),
};

const useStyles = createUseStyles({
    container: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        '& .icon': {
            position: 'relative',
            top: 8,
            fill: 'white',
            width: 20,
            height: 20,
            marginRight: 5,
        },
        '& .value': {
            position: 'relative',
            top: 5,
            fontSize: 23,
        },
    },
});

const ActualVentilation = (props) => {
    const { zoneConfig, systemId } = props;
    const classes = useStyles();
    const { state } = useBravoProxy();
    const actualVentilation = getFunctionUserValue(state, systemId, zoneConfig.ventilation.actual);
    return useMemo(() => {
        return (
            <div className={clsx(classes.container)}>
                <div className='icon'>{icons.ventilator}</div>
                <div className='value'>{actualVentilation}%</div>
            </div>
        );
    }, [actualVentilation]);
};

ActualVentilation.propTypes = {};

export default ActualVentilation;
