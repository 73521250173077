import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';
import VisualContainer from './components/VisualContainer';
import { subscribeToDeviceWebSocket, useBravoProxy } from '../BravoProxy/BravoProxy';
import { getIsWebSocketSubscribed } from './libs/deviceHelper';

const useStyles = createUseStyles({
    container: {
        fontFamily: 'Ubuntu',
        position: 'relative',
        backgroundColor: '#05458d',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#FFFFFF',
        width: 220,
        height: 264,
    },
});

const BravoVisual = (props) => {
    const { systemId } = props;
    const classes = useStyles(props);
    const { state, dispatch } = useBravoProxy();
    const [isLoading, setIsLoading] = useState(true);

    if (!state[systemId]) {
        return null;
    }

    const subscribed = getIsWebSocketSubscribed(state, systemId);

    useEffect(() => {
        setIsLoading(!subscribed);
        if (!subscribed) {
            dispatch(subscribeToDeviceWebSocket(systemId));
        }
    }, [subscribed]);

    return (
        <div className={clsx(classes.container, 'mf_bravo_visual_AZptXa')}>
            {isLoading ? 'Loading' : <VisualContainer {...props} />}
        </div>
    );
};

BravoVisual.propTypes = {};

BravoVisual.defaultProps = {};
BravoVisual.displayName = 'BravoVisual';

export default BravoVisual;
