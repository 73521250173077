import React, { useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';
import { getFunctionSystemValue, getFunctionUserValue } from '../../../../../../libs/functionHelper';
import { useBravoProxy } from '../../../../../../../BravoProxy/BravoProxy';

const icons = {
    // windspeed
    windspeed: (
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 60 60'>
            <g id='art'>
                <path d='M32,30H11v2H32a4.57,4.57,0,0,1,4.42,4.58,4.42,4.42,0,0,1-8.84,0h-2a6.42,6.42,0,0,0,12.84,0A6.59,6.59,0,0,0,32,30Z' />
                <path d='M39.58,15a6.42,6.42,0,0,0-6.41,6.42h2a4.42,4.42,0,1,1,8.83,0C44,23.77,41.57,26,39,26H11v2H39c3.66,0,7-3.14,7-6.58A6.43,6.43,0,0,0,39.58,15Z' />
            </g>
        </svg>
    ),
};

const useStyles = createUseStyles({
    container: {
        display: 'flex',
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: 110,
        height: 40,
        opacity: 0.4,
        alignItems: 'center',
        justifyContent: 'flex-end',
        '& .icon': {
            width: 40,
            height: 40,
            fill: '#FFFFFF',
        },
        '& .value': {
            paddingLeft: 10,
            fontSize: 18,
        },
    },
});

const IconRight = (props) => {
    const { systemId } = props;
    const { state } = useBravoProxy();
    const windSpeedEnabled = getFunctionSystemValue(state, systemId, '800');

    return useMemo(() => {
        if (windSpeedEnabled === '1') {
            return <WindSpeedControl {...props} />;
        }
        return null;
    }, [windSpeedEnabled]);
};

const WindSpeedControl = (props) => {
    const { systemId } = props;
    const classes = useStyles();
    const { state } = useBravoProxy();
    const value = getFunctionUserValue(state, systemId, '804');
    return useMemo(() => {
        return (
            <div className={clsx(classes.container)}>
                <div className='value'>{value} m/s</div>
                <div className='icon'>{icons.windspeed}</div>
            </div>
        );
    }, [value]);
};

IconRight.propTypes = {};

export default IconRight;
