import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';
import { getAlarms, getAlarmWarnings } from '../../libs/alarmHelper';
import { useBravoProxy } from '../../../BravoProxy/BravoProxy';

const useStyles = createUseStyles({
    container: {
        position: 'absolute',
        top: 0,
        right: 0,
        width: 40,
        height: 40,
        '& .icon': {
            opacity: 0.4,
            fill: 'white',
        },
        '&.active': {
            backgroundColor: '#d01322',
            '&.snoozed': {
                backgroundColor: '#da8f24',
            },
            '& .icon': {
                opacity: 1,
            },
        },
    },
    icon: {
        opacity: 0.4,
        fill: 'white',
    },
});

const icons = {
    default: (
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 60 60'>
            <g id='art'>
                <path d='M30,44a5.79,5.79,0,0,0,5-3H25A5.8,5.8,0,0,0,30,44Z' />
                <path d='M43.45,36.68c-3-3.54-5-7.08-5-12.9,0-.64,0-1.27.06-1.86,0-3.28-2.67-6-6.2-6.84a2.35,2.35,0,0,0-4.66,0c-3.52.88-6.2,3.56-6.2,6.83,0,.6.06,1.23.06,1.87,0,5.82-2,9.36-5,12.9A1.86,1.86,0,0,0,16,38a2.24,2.24,0,0,0,2.39,2H41.61A2.24,2.24,0,0,0,44,38,1.78,1.78,0,0,0,43.45,36.68Zm-14.6-18a1.06,1.06,0,0,1-.58.65,2.89,2.89,0,0,0-1.74,2.52c0,.38,0,1.16.06,1.58,0,.17,0,.29,0,.3a21.56,21.56,0,0,1-2.13,9.7,1.28,1.28,0,0,1-1.15.65,1.5,1.5,0,0,1-.47-.08,1.2,1.2,0,0,1-.69-.59.91.91,0,0,1,0-.79,19.62,19.62,0,0,0,1.94-8.87L24,21.69a.36.36,0,0,1,0-.11s0,0,0-.06a5,5,0,0,1,3-4,1.34,1.34,0,0,1,1.69.38A.9.9,0,0,1,28.85,18.68Z' />
            </g>
        </svg>
    ),
    abbi_dcc: (
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 60 60'>
            <g id='art'>
                <path d='M16.85,44.72l-.32-.07-.4,0a2.88,2.88,0,0,0-.41,0,5.69,5.69,0,0,0-1.18.12,3.46,3.46,0,0,0-1,.39,2,2,0,0,0-.66.7,2.13,2.13,0,0,0-.25,1,2.54,2.54,0,0,0,.21,1.08,1.82,1.82,0,0,0,.59.72,2.74,2.74,0,0,0,.9.4,5,5,0,0,0,1.13.12l.88,0,.82-.08.68-.09.47-.08V44.32a3.94,3.94,0,0,0-.15-1.12,2.06,2.06,0,0,0-1.33-1.46,3.92,3.92,0,0,0-1.33-.2,7.27,7.27,0,0,0-1.27.1,4.37,4.37,0,0,0-.84.22l.15,1.08a3.53,3.53,0,0,1,.73-.19,6.12,6.12,0,0,1,1.12-.09,2.09,2.09,0,0,1,.82.14,1.25,1.25,0,0,1,.5.4,1.5,1.5,0,0,1,.25.56,2.7,2.7,0,0,1,.07.63v.37Zm.2,3.24a3,3,0,0,1-.59.08l-.82,0a2.35,2.35,0,0,1-1.22-.27,1,1,0,0,1-.45-1,.94.94,0,0,1,.18-.61,1.28,1.28,0,0,1,.46-.37,1.9,1.9,0,0,1,.6-.17,4.59,4.59,0,0,1,.62,0,5.69,5.69,0,0,1,.77,0,2.26,2.26,0,0,1,.45.12Z' />
                <path d='M20.45,38.36V47.1A2,2,0,0,0,21,48.62a2.46,2.46,0,0,0,1.7.52l.19-1.09a2.75,2.75,0,0,1-.52-.11.75.75,0,0,1-.34-.19.75.75,0,0,1-.18-.33,2,2,0,0,1-.06-.53V38.14Z' />
                <path d='M24.58,48.63a2.65,2.65,0,0,0,.9.4,5,5,0,0,0,1.13.12l.88,0,.82-.08L29,49l.47-.08V44.32a3.94,3.94,0,0,0-.15-1.12A2.06,2.06,0,0,0,28,41.74a3.92,3.92,0,0,0-1.33-.2,7.27,7.27,0,0,0-1.27.1,4.37,4.37,0,0,0-.84.22l.15,1.08a3.53,3.53,0,0,1,.73-.19,6.12,6.12,0,0,1,1.12-.09,2.09,2.09,0,0,1,.82.14,1.25,1.25,0,0,1,.5.4,1.5,1.5,0,0,1,.25.56,2.7,2.7,0,0,1,.07.63v.37l-.2,0-.32-.07-.4,0a2.88,2.88,0,0,0-.41,0,5.69,5.69,0,0,0-1.18.12,3.46,3.46,0,0,0-1,.39,2,2,0,0,0-.66.7,2.13,2.13,0,0,0-.25,1A2.54,2.54,0,0,0,24,47.91,1.82,1.82,0,0,0,24.58,48.63Zm.7-2.42a1.28,1.28,0,0,1,.46-.37,1.9,1.9,0,0,1,.6-.17,4.59,4.59,0,0,1,.62,0,5.69,5.69,0,0,1,.77,0,2.26,2.26,0,0,1,.45.12V48a3,3,0,0,1-.59.08l-.82,0a2.35,2.35,0,0,1-1.22-.27,1,1,0,0,1-.45-1A.94.94,0,0,1,25.28,46.21Z' />
                <path d='M34.68,41.59a2.62,2.62,0,0,0-.38,0,8,8,0,0,0-1.54.14,9.46,9.46,0,0,0-1.15.32v7h1.3V42.88l.47-.1a3.53,3.53,0,0,1,.72-.08,4.55,4.55,0,0,1,.92.08,3.57,3.57,0,0,1,.51.13l.22-1.13-.25-.07-.39-.07Z' />
                <path d='M46.91,49V44.91a8,8,0,0,0-.1-1.34,2.75,2.75,0,0,0-.41-1.07,2,2,0,0,0-.83-.69,3.37,3.37,0,0,0-1.37-.24,3.62,3.62,0,0,0-.82.09,5,5,0,0,0-.68.21,3.71,3.71,0,0,0-.52.24l-.31.2a2.1,2.1,0,0,0-.8-.56,3.27,3.27,0,0,0-1.17-.18,10.6,10.6,0,0,0-1.7.12L37,41.93V49h1.31V42.85l.22,0,.37-.05.43,0,.42,0a1.69,1.69,0,0,1,.76.15,1.12,1.12,0,0,1,.48.46,2.18,2.18,0,0,1,.24.77,6.92,6.92,0,0,1,.07,1.1V49h1.3V44.91a6.66,6.66,0,0,0,0-.91,5.92,5.92,0,0,0-.17-.8,2.38,2.38,0,0,1,.58-.31,2.85,2.85,0,0,1,1.09-.18,1.73,1.73,0,0,1,.77.15,1.12,1.12,0,0,1,.48.46,2,2,0,0,1,.24.77,8.55,8.55,0,0,1,.06,1.1V49Z' />
                <path d='M29,12,17,22.67V13H15V35h2V25.35L29,36ZM27,31.55,18.51,24,27,16.46Z' />
                <path d='M31.62,26.15a1,1,0,0,0,1.3-.54s.8-1.82,2.08-1.82a3.31,3.31,0,0,1,1.87,1A4.87,4.87,0,0,0,40,26.23c2.4,0,4.54-2.52,4.77-2.81a1,1,0,1,0-1.54-1.27c-.45.54-2,2.08-3.23,2.08a3.27,3.27,0,0,1-1.87-1A4.86,4.86,0,0,0,35,21.79c-2.64,0-3.87,2.94-3.93,3.06A1,1,0,0,0,31.62,26.15Z' />
                <path d='M32.5,20.31a1,1,0,0,0,1-.91c.06-.59.46-2.24,1.55-2.73a2.81,2.81,0,0,1,2,.15,4.59,4.59,0,0,0,3.38.06c2.2-1,3.47-4.16,3.6-4.52a1,1,0,0,0-1.86-.72c-.3.77-1.33,2.87-2.55,3.41a2.76,2.76,0,0,1-2-.15,4.65,4.65,0,0,0-3.38-.05c-2.39,1.06-2.72,4.23-2.73,4.36a1,1,0,0,0,.9,1.09Z' />
                <path d='M38.92,35.26a4.3,4.3,0,0,0,1.74.35,8.07,8.07,0,0,0,3.61-1,1,1,0,0,0,.37-1.36,1,1,0,0,0-1.36-.38c-.59.34-2.39,1.09-3.54.58a4.12,4.12,0,0,1-1.56-1.79,5.73,5.73,0,0,0-2.46-2.59,4.11,4.11,0,0,0-4.66,1.3,1,1,0,0,0,.1,1.4,1,1,0,0,0,1.41-.09c0-.05,1.17-1.3,2.34-.78a4.12,4.12,0,0,1,1.56,1.79A5.76,5.76,0,0,0,38.92,35.26Z' />
            </g>
        </svg>
    ),
};

const AlarmIcon = (props) => {
    const { systemId } = props;
    const classes = useStyles();
    const { state } = useBravoProxy();
    const alarms = getAlarms(state, systemId);
    const warnings = getAlarmWarnings(state, systemId);

    return useMemo(() => {
        return (
            <div
                className={clsx(classes.container, {
                    active: alarms.length > 0,
                    snoozed: alarms.length === warnings.length,
                })}
            >
                <div className={clsx('icon')}>{icons.default}</div>
            </div>
        );
    }, [alarms, warnings]);
};

AlarmIcon.propTypes = {};

export default React.memo(AlarmIcon);
