import { isEqual } from 'lodash';
import { BravoProxyConfig } from '../../../config';
import {uniqWith} from './util';

const DEVICE_CONFIGURATION_STORAGE_PREFIX_KEY = 'bravo_visual_';

export const getDeviceConfigurationKey = (product, version) => `${product}_${version}`;

export const loadDeviceConfigurations = async (devices) => {
    const deviceConfigurations = {};
    const uniqueProducts = getUniqueProducts(devices);
    for (const deviceIndex in uniqueProducts) {
        const device = devices[deviceIndex];
        const { product, version } = device;

        // retrieve from cloud or local storage
        const deviceConfiguration = await loadDeviceConfiguration(product, version);
        const deviceConfigurationKey = getDeviceConfigurationKey(product, version);
        if (deviceConfiguration) {
            deviceConfigurations[deviceConfigurationKey] = deviceConfiguration;
        }
    }
    return deviceConfigurations;
};

export const getUniqueProducts = (devices) => {
    return uniqWith(
        Object.values(devices.map((item) => ({ product: item.product, version: item.version }))),
        (a,b) => isEqual(a,b),
    );
};

export const loadDeviceConfiguration = async (product, version) => {
    const storageKey = `${DEVICE_CONFIGURATION_STORAGE_PREFIX_KEY}${getDeviceConfigurationKey(product, version)}`;

    let deviceConfiguration = localStorage.getItem(storageKey);
    if (deviceConfiguration) deviceConfiguration = JSON.parse(deviceConfiguration);
    else {
        // initialize device configuration
        deviceConfiguration = {
            functions: {},
            icons: {},
            alarms: {},
        };

        // fetch device configuration information
        try {
            const response = await fetch(`${BravoProxyConfig.configBackend}/api/config/${product}/${version}`);
            if (response.status === 200) {
                const config = await response.json();
                if (config) {
                    // process functions
                    config.functions.forEach((item) => {
                        deviceConfiguration.functions[item.functionId] = item;
                    });
                    // process icons
                    config.icons.forEach((item) => {
                        deviceConfiguration.icons[item.name] = item;
                    });
                    // process alarms
                    config.alarms.forEach((item) => {
                        const key = `${item.code}_${item.extraAlarmIndex}`;
                        deviceConfiguration.alarms[key] = item;
                    });

                    if (process.env.NODE_ENV === 'production') {
                        localStorage.setItem(storageKey, JSON.stringify(deviceConfiguration));
                    }
                }
            } else {
                deviceConfiguration = null;
            }
        } catch (err) {
            deviceConfiguration = null;
        }
    }

    return deviceConfiguration;
};
