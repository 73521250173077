export const subscriptToDevices = (client, systemIds = []) => {
    sendMessage(client, {
        type: 'subscribe_to_devices',
        data: {
            systemIds: systemIds,
            functions: true,
            alarms: true,
            onlineState: true,
        },
    });
};

export const subscribeToDevice = (client, systemId) => {
    return subscriptToDevices(client, [systemId]);
};

export const sendMessage = (client, message) => {
    if (client && client.readyState === 1) {
        if (typeof message !== 'string') {
            message = JSON.stringify(message);
        }
        client.send(message);
    }
};
